import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import ReactPlayer from "react-player";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import parse from "html-react-parser";
import Img from "gatsby-image";

const query = graphql`
	query {
		strapiPageLesStrategiesDApprentissageEnPnl {
			title
			slug
			bodyUp
			bodyBottom
			video
			seo {
				metaDescription
				metaTitle
			}
		}
		memorisationPicture: file(
			relativePath: {
				eq: "pedagogie-pnl-installation-de-la-stratégie-de-mémorisation-en-individuel.png"
			}
		) {
			childImageSharp {
				fluid(srcSetBreakpoints: [600], maxWidth: 600) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		lessonHistoryPicture: file(
			relativePath: {
				eq: "pedagogie-pnl-schématisation-par-des-enfants-de-leur-leçon-d'histoire.jpg"
			}
		) {
			childImageSharp {
				fluid(srcSetBreakpoints: [600], maxWidth: 600) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;

const LesStrategiesDApprentissageEnPnl = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageLesStrategiesDApprentissageEnPnl;
	const breakpoints = useBreakpoint();

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className={`grid grid-cols-1 sub-container`}>
						<p>
							Vous voulez éviter la <b>perte de motivation</b> et le{" "}
							<b>décrochage scolaire</b> chez les jeunes malgré les efforts
							fournis ?
						</p>

						<p>Il existe des solutions proposées par la PNL.</p>

						<p>
							Des <b>maternelles</b> en passant par les <b>primaires</b> et les{" "}
							<b>secondaires</b> voire aussi jusqu'à <b>l'université</b>, ces
							stratégies sont adaptées à tous les apprenants.
						</p>

						<p>
							En <b>observant</b> et <b>modélisant</b> les{" "}
							<b>élèves brillants</b>, les PNListes ont dégagé 5 stratégies
							d'apprentissage pour aider les élèves en difficultés :
						</p>
						<div
							className={`grid grid-cols-1 sm:grid sm:grid-cols-3 gap-3 strategies`}
						>
							<div
								className={`sm:col-start-1 strategies__item strategies__item--left`}
							>
								<h2 className={`item__name`}>Stratégie de Mémorisation</h2>
							</div>
							<div
								className={`sm:col-start-3 strategies__item strategies__item--right`}
							>
								<h2 className={`item__name`}>Stratégie de Compréhension</h2>
							</div>
							<div className={`sm:col-start-2 strategies__item`}>
								<h2 className={`item__name`}>Stratégie de Réflexion</h2>
							</div>
							<div
								className={`sm:col-start-1 strategies__item strategies__item--left`}
							>
								<h2 className={`item__name`}>Stratégie de Prononciation</h2>
							</div>
							<div
								className={`sm:col-start-3 strategies__item strategies__item--right`}
							>
								<h2 className={`item__name`}>Stratégie de Transfert</h2>
							</div>
						</div>
						<p>
							Elles sont à la fois <b>utiles pour les jeunes</b> afin de les
							aider à mieux apprendre et pour <b>les enseignants</b> afin de
							leur donner des pistes pour guider les jeunes dans les opérations
							mentales à effectuer.
						</p>
						<p>Mais qu'est-ce qu'une stratégie mentale ?</p>
						<p>
							Une stratégie mentale est une séquence de représentations
							sensorielles – des images, des sons, des sensations et une petite
							voix intérieure — qui a pour but de mener un apprentissage, de
							prendre une décision, de développer sa créativité, de favoriser la
							motivation...
						</p>
						<p>
							<b>
								La spécificité de la pédagogie PNL est d'avoir réalisé qu'un
								jeune pendant une simple heure de cours devra passer par toutes
								les opérations mentales : visuelles, kinesthésiques, auditives
								et dialogue interne. Il devra donc adapter sa cognition à
								l'objectif pédagogique. EX : Si mémoriser ou réfléchir ne
								nécessite pas les mêmes opérations, l'enfant devra néanmoins
								savoir faire les deux.
							</b>
						</p>
						<p>
							En PNL, il s'agira de lui apprendre à utiliser toutes ses
							possibilités afin qu'il ne se limite pas à ce qu'il fait
							d'habitude.
						</p>

						<div className="shape">
							<div className="shape__content">
								<h3>Séquence d'intervention en pédagogie PNL</h3>

								<p>
									Il est d'abord question <b>"d'apprendre à apprendre"</b>,
									c'est-à-dire de donner les outils cognitifs suffisants aux
									jeunes en{" "}
									<b>
										intégrant les stratégies d'apprentissage avec du contenu
										simple
									</b>{" "}
									pour ensuite leur apprendre à utiliser ces stratégies
									cognitives dans toutes les disciplines scolaires et à tous les
									âges.
								</p>
								<p>
									Nous accordons une attention particulière à toutes les notions
									essentielles, celles que les élèves doivent absolument
									acquérir pour évoluer positivement vers les cycles suivants.
								</p>
							</div>
						</div>

						<h2 className="subtitle">
							Intégrer les stratégies d'apprentissage en PNL
						</h2>

						<p>
							Il s'agit d'utiliser des tâches scolaires simples qui ne
							nécessitent qu'une seule des stratégies ci-dessous pour
							"installer" correctement celle-ci au travers de différents
							exercices.
						</p>

						<h3>Compréhension</h3>

						<p>
							Pour favoriser la compréhension, il est important d'aider les
							enfants à développer leur capacité de visualisation du sens de ce
							qu'ils étudient. Pour être sûr que celle-ci soit correcte,
							complète et structurée, nous leur apprenons à schématiser le sens
							juste des contenus.
						</p>
						<p>
							Nous distinguons 3 types de problèmes de compréhension pour
							lesquels nous avons construit des séquences de travail qui
							permettent à n'importe quel enfant d'y remédier et ainsi, de
							dépasser ses difficultés.
						</p>

						<p className="text-center mb-6">
							<Img
								fluid={data.lessonHistoryPicture.childImageSharp.fluid}
								className="picture"
							/>
							<i>Schématisation par des enfants de leur leçon d'histoire</i>
						</p>

						<h3>Mémorisation</h3>

						<p>
							Pour mémoriser, les élèves brillants prennent d'abord le temps de
							bien regarder les mots, dates et schémas de leurs notes en les
							visualisant. Ils font ensuite l'effort mental de les re-voir dans
							leur tête (visuel) puis de ressentir combien cela leur semble
							correct et complet (kinesthésique).
						</p>

						<p className="text-center mb-6">
							<Img
								fluid={data.memorisationPicture.childImageSharp.fluid}
								className="picture"
							/>
							<i>
								Installation de la stratégie de mémorisation (ici en individuel)
							</i>
						</p>

						<p>
							Il faudra que l'enfant révise plusieurs fois et qu'il crée un lien
							entre ce qu'il a vu et le <b>futur</b> pour ancrer ce contenu à
							long terme.
						</p>

						<h3>Réflexion</h3>

						<p>
							Lorsque les jeunes ont complètement compris les explications du
							livre ou de leur professeur, nous pouvons les inviter à se poser
							des questions pertinentes (dans leur dialogue interne) : des
							questions qui les guident :
						</p>
						<ul>
							<li>
								à entrevoir des liens entre différentes parties du contenu,
							</li>
							<li>à repérer des distinctions entre niveaux d'informations,</li>
							<li>à percevoir ce qu'ils en pensent eux-mêmes du contenu,</li>
							<li>...</li>
						</ul>

						<p>
							La pédagogie PNL propose une série de questions très spécifiques
							pour leur apprendre à réfléchir, commenter et argumenter.
						</p>

						<h3>Prononciation</h3>

						<p>
							Le piège pour un jeune est d'utiliser le dialogue interne (la
							petite voix intérieure) pour développer la stratégie de
							prononciation. Ce biais le conduira toujours en langues
							étrangères, par exemple, à avoir un mauvais accent.
						</p>
						<p>
							Toute la nuance et la difficulté est de lui apprendre à faire une
							distinction entre le mot lu et le mot prononcé avec une opération
							mentale d'auditif remémoré.
						</p>

						<div className={`player-container`}>
							<div className="player-wrapper">
								<ReactPlayer
									url={page.video}
									className="react-player"
									width="100%"
									height="100%"
									loop={true}
									controls={true}
								/>
							</div>
						</div>

						<h2 className="subtitle">
							Utiliser les stratégies dans les différentes disciplines
							scolaires.
						</h2>

						<p>
							Lorsque les jeunes ont intégré les différentes stratégies, nous
							pouvons leur montrer comment les utiliser dans toutes les
							disciplines et particulièrement pour des notions scolaires
							nécessitant parfois l'usage de toutes les stratégies en même temps
							comme en maths, en grammaire ou encore l'étude de cours à contenu
							(histoire / géographie / sciences).
						</p>

						<h2 className="subtitle">Notions essentielles ?</h2>

						<p>
							Certaines notions scolaires sont "essentielles" parce qu'elles
							sont le socle de bien des apprentissages dans la suite de la
							scolarité comme la priorité des opérations mathématiques ou la
							différence entre la cause et la conséquence.
						</p>
						<p>
							Si nous laissons souvent les enfants se construire leurs propres
							images mentales qu'ils schématiseront comme ils le veulent, avec
							<b> les notions essentielles</b>, nous ne prenons pas le risque
							que les images choisies soient imprécises.
						</p>
						<p>
							Nous avons donc, pour certains concepts de base essentiels,
							particulièrement travaillé avec les jeunes pour trouver l'image la
							plus claire et la plus précise pour tout le monde.{" "}
						</p>
						<p>
							Ce travail a généré la fabrication d'outils pédagogiques
							spécifiques (matériel nouveau ou déjà existant auquel on a ajouté
							des explications relatives à la pédagogie PNL.)
						</p>

						<h2 className="subtitle">Se former</h2>

						<p>
							Il est important de <b>se former</b> afin d'installer correctement
							chaque stratégie chez le jeune avant de les utiliser dans les
							diverses disciplines.
						</p>
						<p>
							Même si le jeune doit toujours faire l'effort d'apprendre, ces
							stratégies lui permettront de{" "}
							<b>réactiver sa motivation face au travail</b> et de{" "}
							<b>prendre confiance dans sa capacité à apprendre</b>.
						</p>
						<div className={`button-action`}>
							<a
								href="https://www.interactif.be/apprentissage_index.php"
								target="_blank"
								rel="noopener"
							>
								<button className={`btn btn--green`}>
									Se former aux {breakpoints.small ? parse(`<br></br>`) : ``}
									stratégies d'apprentissage
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default LesStrategiesDApprentissageEnPnl;
